import styled, { css } from "styled-components";

import { size } from "~/styles/screen";

interface Props {
  isCollapsed: boolean;
}

export const Container = styled.div<Props>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  min-height: 1000px;
  max-height: 5000px;
  transition: max-height 0.5s cubic-bezier(1, 0, 1, 0);

  background-color: #114b5f;
  padding: 35px;

  ${(props) =>
    props.isCollapsed &&
    css`
      max-height: 1000;
      transition: max-height 0.85 cubic-bezier(0, 1, 0, 1);
    `}

  @media screen and (max-width: ${size.small}) {
    padding: 0;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
  margin-bottom: 25px;
`;

export const Text = styled.text`
  font-size: 18px;
  text-align: center;
  font-weight: 400;
  font-family: "Montserrat";
  line-height: 21.6px;
  color: white;
`;

export const Title = styled.h1`
  font-size: 42px;
  text-align: center;
  font-weight: 900;
  font-family: "Roboto Slab";
  line-height: 55.39px;
  color: white;
`;

export const Anchor = styled.a`
  color: black;
  font-weight: 700;
  text-decoration: underline;
`;

export const UnorderedList = styled.ul`
  list-style-type: disc;
  color: black;
  margin-left: 1rem;
  width: 100%;
  padding: 30px;
`;

export const OrderedList = styled.ol`
  color: black;
  margin-left: 1rem;
  width: 100%;
  padding: 0 30px;
  margin-bottom: 30px;
`;

export const Li = styled.li`
  text-align: left;
  font-size: 18px;
  font-weight: 400;
  font-family: "Montserrat";
  line-height: 25.6px;
  color: black;
  @media screen and (max-width: ${size.small}) {
    & {
      font-size: 16px;
    }
  }
`;

export const Paragraph = styled.p`
  text-align: left;
  margin-bottom: 20px;
`;
