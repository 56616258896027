import React from "react";

import Link from "next/link";
import { createGlobalStyle } from "styled-components";

import * as Grid from "~/components/Grid";
import * as Navbar from "~/components/Navbar";
import { Viewport } from "~/layouts/_common";

export const FocusGlobalStyle = createGlobalStyle`
  html, body {
    background-color: ${(props) => props.theme.colors.backgroundGray};
  }
`;

export const FocusGlobalStyleNotFound = createGlobalStyle`
  html, body,div {
    background-color: ${(props) => props.theme.colors.backgroundGray};
  }
`;

type Props = React.ComponentProps<typeof DashboardContent>;
type DashboardFocusLayoutProps = Props & { viewportCSS?: Record<string, string> };

export default function DashboardFocusLayout(props: DashboardFocusLayoutProps) {
  return (
    <Viewport style={props.viewportCSS}>
      <FocusGlobalStyle />

      <DashboardNavbar />
      <DashboardContent {...props} />
    </Viewport>
  );
}

export function DashboardFocusLayoutNotFound(props: Props) {
  return (
    <Viewport>
      <FocusGlobalStyleNotFound />

      <DashboardNavbar />
      <DashboardContent {...props} />
    </Viewport>
  );
}

export function DashboardNavbar() {
  return (
    <Navbar.Container spaced transparent>
      <Navbar.ContentBrand>
        <Link href="/dashboard/outgoing" passHref>
          <Navbar.Item>
            <Navbar.Logo />
          </Navbar.Item>
        </Link>
      </Navbar.ContentBrand>
    </Navbar.Container>
  );
}

export function DashboardContent({
  fluidness = "fluid",
  gapless = false,
  as,
  theme,
  forwardedAs,
  ...props
}: React.ComponentProps<typeof Grid.Container>) {
  return (
    <Grid.Container as={as} fluidness={fluidness} gapless={gapless} theme={theme} forwardedAs={forwardedAs}>
      <main {...props} />
    </Grid.Container>
  );
}
