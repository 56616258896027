import clsx from "clsx";
import styled, { css } from "styled-components";

import { from } from "~/styles/theme";
import * as Bulma from "~/types/bulma";

/**
 * https://bulma.io/documentation/elements/box/
 */
export const Box = styled.div<Bulma.Kinded>`
  display: block;
  background-color: ${(props) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return props.theme.colors[props.kind || "main"];
  }};
  border-radius: ${(props) => props.theme.radius};
  box-shadow: ${(props) => props.theme.shadows.soft};
  color: ${(props) => props.theme.colors.text};
  padding: 0;
`;

export const BoxDanger = styled(Box)`
  background-color: #ffdfdc;
  border: 1px solid #ffc0b9;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
`;

/**
 * https://bulma.io/documentation/elements/content/
 */
export const Content = styled.div.attrs<Bulma.Sized>((props) => ({
  className: clsx({
    content: true,
    [`is-${props.sized}`]: Boolean(props.sized),
  }),
}))<Bulma.Sized>``;

export const Padding = styled.div<Pick<Bulma.Common, "sized"> & { vertical?: boolean; horizontal?: boolean }>`
  padding: ${(props) => {
    switch (props.sized || "") {
      case "small":
        return "calc(0.7rem * 1)";
      case "normal":
        return "calc(0.7rem * 2)";
      case "medium":
        return "calc(0.7rem * 4)";
      case "large":
        return "calc(0.7rem * 6)";
      default:
        return "0";
    }
  }};

  & + & {
    padding-top: 0;
  }

  ${(props) =>
    !props.vertical &&
    css`
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    `}
  ${(props) =>
    !props.horizontal &&
    css`
      padding-left: 0 !important;
      padding-right: 0 !important;
    `}
`;
Padding.defaultProps = {
  sized: "normal",
  vertical: true,
  horizontal: true,
};

export const PaddingResponsive = styled.div<{ vertical?: boolean; horizontal?: boolean }>`
  ${from.mobile(css`
    padding: calc(0.7rem * 1);
  `)}
  ${from.tablet(css`
    padding: calc(0.7rem * 2);
  `)}
  ${from.desktop(css`
    padding: calc(0.7rem * 4);
  `)}

  ${(props) =>
    !props.vertical &&
    css`
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    `}
  ${(props) =>
    !props.horizontal &&
    css`
      padding-left: 0 !important;
      padding-right: 0 !important;
    `}
`;
PaddingResponsive.defaultProps = {
  vertical: true,
  horizontal: true,
};

/**
 * Padding from Bulma's section was moved to Padding component.
 * https://github.com/jgthms/bulma/blob/master/sass/layout/section.sass
 */

/**
 * @deprecated Replace by html <section>
 */
export const Section = styled.section``;

export const Separator = styled.hr<Bulma.Sized & Bulma.Colorable & Bulma.TextWeight>`
  &&& {
    background-color: ${(props) => {
      // TODO
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return props.theme.colors[props.color || "backgroundGray"];
    }};
    border: none;
    display: block;
    height: 2px;
    height: ${(props) => {
      switch (props.weight) {
        case "light":
          return `1px`;
        case "normal":
          return `2x`;
        case "semibold":
          return `3px`;
        case "bold":
          return `4px`;
        case "bolder":
          return `5px`;
        default:
          return 0;
      }
    }};
    margin: ${(props) => {
      switch (props.sized) {
        case "small":
          return `calc(0.75rem * 0.5)`;
        case "normal":
          return `calc(0.75rem * 1)`;
        case "medium":
          return `calc(0.75rem * 2)`;
        case "large":
          return `calc(0.75rem * 3)`;
        default:
          return 0;
      }
    }};
    margin-left: 0;
    margin-right: 0;
  }
`;
Separator.defaultProps = {
  weight: "normal",
  sized: "medium",
};
