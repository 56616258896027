import { useRouter } from "next/router";

import { Button, NEW_TAB } from "./Button";
import { Content, Padding } from "./Elements";
import { Title } from "./Text";

import OnboardingLayout from "~/layouts/Onboarding";
import { Paragraph } from "~/templates/network/components/FAQ/styles";

type Props = {
  title?: string;
  status?: number;
  description?: string;
};

export function CenteredPage({ title, description, status, ...props }: Props) {
  const router = useRouter();

  return (
    <OnboardingLayout fluidness="tablet" {...props}>
      <section>
        <Padding sized="medium">
          {title && (
            <Title as="h1">
              {status} {title}
            </Title>
          )}
          {description && <Paragraph as="h2">{description}</Paragraph>}
          <Content>
            <Button as="a" kind="primary" onClick={router.back}>
              Go back
            </Button>
            <Button as="a" kind="text" href="mailto:support@notabene.id" {...NEW_TAB}>
              Contact us
            </Button>
          </Content>
        </Padding>
      </section>
    </OnboardingLayout>
  );
}
