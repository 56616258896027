import React from "react";

import { CenteredPage } from "~/components/CenteredPage";

type Props = {
  title?: string;
  status?: number;
};

export default function NotFoundPage({ title = "Not Found", status = 404, ...props }: Props) {
  return <CenteredPage title={title} status={status} {...props} />;
}
