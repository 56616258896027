import React from "react";

import Link from "next/link";

import * as Navbar from "~/components/Navbar";
import { Viewport } from "~/layouts/_common";
import { FocusGlobalStyle, DashboardContent } from "~/layouts/DashboardFocus";

type Props = React.ComponentProps<typeof DashboardContent>;

export default function OnboardingLayout(props: Props) {
  return (
    <Viewport>
      <FocusGlobalStyle />
      <DashboardNavbar />
      <DashboardContent {...props} />
    </Viewport>
  );
}

export function DashboardNavbar() {
  return (
    <Navbar.Container spaced transparent>
      <Navbar.ContentBrand>
        <Link href="/" passHref>
          <Navbar.Item>
            <Navbar.Logo />
          </Navbar.Item>
        </Link>
      </Navbar.ContentBrand>
    </Navbar.Container>
  );
}
